<template>
    <layout-full-width :title="campaign !== null ? campaign.campaignDescLong : ''">

        <div class="card mb-3">
            <div class="card-header">{{ $tc('campaign', 1) }}</div>
            <div class="card-body p-0" v-if="!loading.CAMPAIGN_GET">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $t('short-name') }}</strong>
                        <span>{{ campaign.campaignDescShort }}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $t('long-name') }}</strong>
                        <span>{{ campaign.campaignDescLong }}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $tc('type', 1) }}</strong>
                        <span class="badge d-flex align-items-center"
                              :class="getCampaignTypeClass(campaign.campaignType)">
                            {{ getCampaignTypeLabel(campaign.campaignType) }}
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $tc('status', 1) }}</strong>
                        <span class="badge d-flex align-items-center"
                              :class="getCampaignStatusClass(campaign.campaignStatus)">
                            {{ getCampaignStatusLabel(campaign.campaignStatus) }}
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $tc('media-format', 1) }}</strong>
                        <span>Offline Print Marketing</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $t('modified') }}</strong>

                        <template v-if="campaign.metaUpdateDt">
                            {{ campaign.metaUpdateDt | timestamp }}
                        </template>
                        <template v-else>
                            {{ campaign.metaInsertDt | timestamp }}
                        </template>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $t('campaign-id') }}</strong>
                        <span class="badge bg-light d-flex align-items-center">
                            {{ campaign.campaignUniqueId }}
                        </span>
                    </li>
                </ul>
            </div>
            <div class="card-body p-0" v-if="loading.CAMPAIGN_GET">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $t('short-name') }}</strong>
                        <component-shimmer width="150px"/>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $t('long-name') }}</strong>
                        <component-shimmer width="250px"/>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $tc('type', 1) }}</strong>
                        <component-shimmer width="50px"/>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $tc('status', 1) }}</strong>
                        <component-shimmer width="75px"/>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $tc('media-format', 1) }}</strong>
                        <component-shimmer width="150px"/>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $t('modified') }}</strong>
                        <component-shimmer width="100px"/>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <strong>{{ $t('campaign-id') }}</strong>
                        <component-shimmer width="208.37px"/>
                    </li>
                </ul>
            </div>
        </div>

        <template v-if="campaign.campaignStatus === 'CAMPAIGN_IN_PROGRESS' || loading.CAMPAIGN_LIST_TRIGGER">
            <div class="row">
                <div class="col-12">
                    <div class="px-3 py-5 mx-auto d-flex align-items-center justify-content-center w-100 flex-column"
                         style="max-width: 800px">
                        <p><font-awesome-icon class="text-primary" size="6x" :spin="true" icon="circle-notch"/></p>
                        <h5>{{ $t('pages.campaign-log.loading') }}</h5>
                        <p class="text-center" v-html="$t('pages.campaign-log.no-recipients-found')"/>
                    </div>
                </div>
            </div>
        </template>

        <template v-else-if="campaign.campaignStatus === 'CAMPAIGN_COMPLETED' || campaign.campaignStatus === 'CAMPAIGN_ACTIVE'">
            <div class="card mb-3" v-for="(campaignUser, i) in campaignUsers.list" :key="'campaign-user-' + i">
                <div class="card-header">{{ $tc('detail', 2) }}</div>
                <div class="card-body p-0">
                    <div id="campaign-users-list" class="accordion accordion-flush">
                        <div class="accordion-item"
                             v-for="(campaignVariable, j) in campaignUser.payload.channelData.campaignVariables"
                             :key="'campaign-user-variables-' + 'i' + '-' + j">
                            <h2 class="accordion-header" :id="'campaign-users-heading-' + i + '-' + j">
                                <button class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        :data-bs-target="'#campaign-user-collapse-' + i + '-' + j"
                                        aria-expanded="false"
                                        :aria-controls="'campaign-user-collapse-' + i + '-' + j">
                                    <span class="badge bg-primary me-2">#{{pad(j + 1, 8, '0')}}</span>
                                    <span v-if="campaignUser.payload.triggerResults[j].status" :class="getStatusClass(campaignUser.payload.triggerResults[j].status)" class="badge me-2">
                                        {{ $t('statuses.' + campaignUser.payload.triggerResults[j].status.toLowerCase()) }}
                                    </span>
                                    <span class="badge bg-light font-monospace">
                                        {{ campaignVariable.triggerUniqueId }}
                                    </span>
                                </button>
                            </h2>
                            <div :id="'campaign-user-collapse-' + i + '-' + j"
                                 class="accordion-collapse collapse"
                                 :aria-labelledby="'campaign-users-heading-' + i + '-' + j"
                                 data-bs-parent="#campaign-users-list">
                                <div class="accordion-body">
                                    <div class="d-flex flex-row flex-nowrap">
                                        <div class="w-50 me-3">
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between"
                                                    v-for="(value, key) in campaignVariable"
                                                    :key="'campaign-user-variable-' + i + '-' + j + '-' + key">
                                                    <template v-if="key !== 'triggerUniqueId' && key !== 'contactUniqueId'">
                                                        <strong>{{ key }}</strong>
                                                        <span>{{ value }}</span>
                                                    </template>
                                                    <template v-else>
                                                        <strong>{{ getKeyTranslation(key) }}</strong>
                                                        <span class="badge bg-light font-monospace d-flex align-items-center">{{ value }}</span>
                                                    </template>

                                                </li>
                                            </ul>
                                        </div>
                                        <div class="w-50" v-if="campaignUser.payload.triggerResults[j] && campaignUser.payload.triggerResults[j].status === 'ERROR'">
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between list-group-item-danger"
                                                    v-for="(value, key) in campaignUser.payload.triggerResults[j].data"
                                                    :key="'campaign-user-variable-error-' + i + '-' + j + '-' + key">
                                                    <strong class="me-2">{{ key }}</strong>
                                                    <div>{{ value }}</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "../../layouts/LayoutFullWidth";
import getCampaignTypeClass from "../../lib/get-campaign-type-class";
import getCampaignTypeLabel from "../../lib/get-campaign-type-label";
import getCampaignStatusClass from "../../lib/get-campaign-status-class";
import getCampaignStatusLabel from "../../lib/get-campaign-status-label";
import ComponentShimmer from "../../components/ComponentShimmer";

export default {
    name: "PageCampaignLog",
    components: {ComponentShimmer, LayoutFullWidth},
    computed: {
        loading() {
            return {
                CAMPAIGN_GET: this.$store.state.loading.CAMPAIGN_GET,
                CAMPAIGN_LIST_TRIGGER: this.$store.state.loading.CAMPAIGN_LIST_TRIGGER
            }
        },
        campaignUsers() {
            return this.$store.state.CAMPAIGN.campaignUsers;
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        }
    },
    data() {
        return {
            status: null
        }
    },
    async beforeMount() {
        await this.loadCampaign();

        if (['CAMPAIGN_IN_PROGRESS', 'CAMPAIGN_COMPLETED', 'CAMPAIGN_ACTIVE'].indexOf(this.campaign.campaignStatus) === -1) {
            await this.$router.push({name: 'marketing-campaigns'});
            return;
        }

        if (!this.loading.CAMPAIGN_LIST_TRIGGER && this.campaign.campaignStatus === 'CAMPAIGN_IN_PROGRESS') {
            await this.repeatLoadCampaign();
        }
    },
    methods: {
        pad(n, width, z) {
            z = z || '0';
            n = n + '';
            return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        },
        getKeyTranslation(key) {
            const translations = {
                triggerUniqueId: this.$t('trigger-id'),
                contactUniqueId: this.$t('contact-id')
            }

            if (Object.hasOwnProperty.call(translations, key)) {
                return translations[key];
            }

            return key;
        },
        async repeatLoadCampaign() {
            const that = this;

            setTimeout(async function () {
                await that.loadCampaign();

                if (that.campaign.campaignStatus === 'CAMPAIGN_IN_PROGRESS') {
                    await that.repeatLoadCampaign();
                    that.$forceUpdate();
                }
            }, 15000);
        },
        async loadCampaign() {
            await this.$store.dispatch('CAMPAIGN/GET', this.$route.params.id);
            await this.$store.dispatch('CAMPAIGN/LIST_TRIGGER', {
                campaignUniqueId: this.$route.params.id,
                filter: [
                    {
                        key: 'startDate',
                        value: '2020-01-01'
                    },
                    {
                        key: 'endDate',
                        value: '2120-01-01'
                    }
                ]
            });

            if (this.campaignUsers.list
                && this.campaignUsers.list.length > 0
                && this.campaignUsers.list[0].payload.channelData
            ) {
                this.status = this.campaignUsers.list[0].payload.triggerResults[0].status;
            }
        },
        getStatusClass(status) {
            if (status !== 'SUCCESS' && status !== 'ERROR') {
                return 'bg-light';
            }

            return {
                'bg-success': status === 'SUCCESS',
                'bg-danger': status === 'ERROR'
            }
        },
        getCampaignStatusClass(campaignStatus) {
            return getCampaignStatusClass(campaignStatus);
        },
        getCampaignStatusLabel(campaignStatus) {
            return getCampaignStatusLabel(campaignStatus, this);
        },
        getCampaignTypeClass(campaignType) {
            return getCampaignTypeClass(campaignType);
        },
        getCampaignTypeLabel(campaignType) {
            return getCampaignTypeLabel(campaignType, this);
        },
    }
}
</script>

<style lang="scss" scoped></style>
