export default function getCampaignStatusClass(campaignStatus) {
    return {
        'bg-info': campaignStatus === 'CAMPAIGN_ACTIVE',
        'bg-dark': campaignStatus === 'CAMPAIGN_PAUSED',
        'bg-light': campaignStatus === 'CAMPAIGN_DRAFT',
        'bg-success': campaignStatus === 'CAMPAIGN_COMPLETED',
        'bg-secondary': campaignStatus === 'CAMPAIGN_ARCHIVED',
        'bg-danger': campaignStatus === 'CAMPAIGN_TRASHED',
        'bg-warning': campaignStatus === 'CAMPAIGN_IN_PROGRESS'
    }
}