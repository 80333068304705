<template>
    <div :style="{ width, height }" class="shimmer">&nbsp;</div>
</template>

<script>
export default {
    name: "ComponentShimmer",
    props: {
        width: {
            type: String,
            required: false,
            default: '300px'
        },
        height: {
            type: String,
            required: false,
            default: '18px'
        }
    }
}
</script>

<style lang="scss" scoped>
.shimmer {
    background: #f6f7f8 linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) no-repeat;
    background-size: 800px 104px;
    position: relative;
    width: 100%;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}
</style>